import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkbox"
export default class extends Controller {
  static targets = ["displaysConsultationNeedPrice", "isPrivatePractice"]

  connect() {
    this.togglePrivatePractice()
  }

  togglePrivatePractice() {
    this.isPrivatePracticeTarget.disabled = !this.displaysConsultationNeedPriceTarget.checked
  }
}
